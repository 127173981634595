import { NgModule } from '@angular/core';
import { MsalGuard } from '@azure/msal-angular';
import { RouterModule, Routes } from '@angular/router';
import { ConfigurationComponent } from './modules/configuration/configuration.component';
import { CreateComponent } from './modules/create/create.component';
import { ViewComponent } from './modules/view/view.component';
import { WorkQComponent } from './modules/work-q/work-q.component';
import { UserauthguardGuard } from './userauthguard.guard';
import { AccessDeniedComponent } from './modules/access-denied/access-denied.component';
import { OffVpnComponent } from './modules/off-vpn/off-vpn.component';
import { AdministrationComponent } from './modules/administration/administration.component';
import { CreateRequestResolverService } from './modules/create/CreateItemResolverService';
//import { DashboardComponent } from './modules/dashboard/dashboard.component';

const routes: Routes = [
  {
    path: ':mode/view', component: ViewComponent, canActivate:[UserauthguardGuard]
  },
  {
    path: ':mode/create', component: CreateComponent, canActivate:[UserauthguardGuard], resolve: { preloads: CreateRequestResolverService}
  },
  {
    path: ':mode/copy', component: CreateComponent, canActivate:[UserauthguardGuard], resolve: { preloads: CreateRequestResolverService}
  },
  {
    path: ':mode/edit/:id', component: CreateComponent, canActivate:[UserauthguardGuard], resolve: { preloads: CreateRequestResolverService}
  },
  {
    path: ':mode/copy/:id', component: CreateComponent, canActivate:[UserauthguardGuard], resolve: { preloads: CreateRequestResolverService}
  },
  {
    path: 'work-q', component: WorkQComponent, canActivate:[UserauthguardGuard]
  },
  {
    path: 'work-q/:id', component: WorkQComponent, canActivate:[UserauthguardGuard] //Ken add this to retrieve workq id 
  },
  {
    path: 'configuration', component: ConfigurationComponent, canActivate:[UserauthguardGuard]
  },
  {
    path: 'administration', component: AdministrationComponent, canActivate:[UserauthguardGuard]
  },
  // {
  //   path: 'dashboard', component: DashboardComponent, canActivate:[UserauthguardGuard]
  // },
  {
    path: 'access-denied', component: AccessDeniedComponent
  },
  {
    path: 'off-vpn', component: OffVpnComponent
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {useHash: true ,
    anchorScrolling: 'enabled', relativeLinkResolution: 'legacy'})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
export const routingComponents = [ViewComponent, CreateComponent, WorkQComponent, ConfigurationComponent]
