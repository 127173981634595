import { SortableModule } from '@progress/kendo-angular-sortable';
import { ListBoxModule } from '@progress/kendo-angular-listbox';
import { ToolBarModule } from '@progress/kendo-angular-toolbar';
import { RouterModule } from '@angular/router';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ViewComponent } from './modules/view/view.component';
import { WorkQComponent } from './modules/work-q/work-q.component';
import { ConfigurationComponent } from './modules/configuration/configuration.component';
import { FooterComponent } from './modules/layout-module/components/footer/footer.component';
import { NavigationBarComponent } from './modules/layout-module/components/navigation-bar/navigation-bar.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { GridModule, ExcelModule, ToolbarComponent } from '@progress/kendo-angular-grid';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { InputsModule } from '@progress/kendo-angular-inputs'
import { LayoutModule } from '@progress/kendo-angular-layout';
import { IconsModule } from '@progress/kendo-angular-icons';
import { LabelModule } from '@progress/kendo-angular-label';
import { DateInputsModule } from '@progress/kendo-angular-dateinputs';
import { DialogsModule } from '@progress/kendo-angular-dialog';
import { MultiCheckFilterComponent } from './modules/shared/multicheck-filter';
import { AdministrationComponent } from './modules/administration/administration.component';
import { AccessDeniedComponent } from './modules/access-denied/access-denied.component';
import { StartupService } from './common/startup-service';
import { StatePersistingService } from './common/state-persisting-service';
import { UserService } from './common/user-service'; // WH keep
import { ErrorHandlerService } from './errorHandling/error-handling.service';
import { LoggingService } from './logging/logging.service';
import { MsalBroadcastService, MsalGuard, MsalGuardConfiguration, MsalInterceptor, MsalInterceptorConfiguration,
  MsalModule, MsalService, MSAL_GUARD_CONFIG, MSAL_INSTANCE, MSAL_INTERCEPTOR_CONFIG,MsalRedirectComponent } from '@azure/msal-angular';
import { BrowserCacheLocation, InteractionType, IPublicClientApplication, LogLevel, PublicClientApplication } from '@azure/msal-browser';
import { OffVpnComponent } from './modules/off-vpn/off-vpn.component';
import { environment } from 'environments/environment';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { SafeurlPipe } from './modules/shared/safe-url.pipe';
import { ButtonGroupModule, ButtonsModule } from '@progress/kendo-angular-buttons';
import { BadgeModule, IndicatorsModule } from '@progress/kendo-angular-indicators';
import { LoaderModule } from '@progress/kendo-angular-indicators';
import { UploadsModule } from '@progress/kendo-angular-upload';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CreateComponent } from './modules/create/create.component';
import { ListViewModule } from "@progress/kendo-angular-listview";
import { PopupModule } from '@progress/kendo-angular-popup';
import { RM_NotificationService } from './common/notificationService';
import { HttpErrorHandler } from './common/errorHandler';
import { PopupAnchorDirective } from './common/popup-anchor.directive';
import { DatePipe } from '@angular/common';
import { AppInsightsService, ApplicationInsightsModule } from "@markpieszak/ng-application-insights";
import { NotificationModule } from '@progress/kendo-angular-notification';
import { ContextMenuModule } from "@progress/kendo-angular-menu";
import { OverlayComponent } from './modules/layout-module/components/overlay/overlay.component';
import { StringParsingService } from './common/string-parsing.service';

import {
  QueryApiService,
  CommentsApiService,
  RecordApiService,
} from '@apps-es/api'

export function startupServiceFactory(startupService: StartupService): Function {
  localStorage.removeItem('RmSiteList');
  localStorage.removeItem('RmSite');
  return () => {
      return startupService.loadSites();
  };
}
const isIE = window.navigator.userAgent.indexOf('MSIE ') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1;

export function loggerCallback(logLevel: LogLevel, message: string) {
  console.log(message);
}

export function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication({
    auth: {
      clientId: 'ab51aa18-852d-4e29-aa41-97ce27a94f25',
      authority: 'https://login.microsoftonline.com/46c98d88-e344-4ed4-8496-4ed7712e255d',
      navigateToLoginRequestUrl: true,
      // *** Make sure you uncomment below lines with environment.urlBase BEFORE you publish to AzureDevOps ***
      redirectUri: environment.urlBase + 'frameRedirect.html',
      postLogoutRedirectUri: environment.urlBase + 'frameRedirect.html',
      // *** Uncomment below for local development use case. Comment these lines out BEFORE you publish to AzureDevOps
      // redirectUri: 'http://localhost:4300/frameRedirect.html',
      // postLogoutRedirectUri: 'http://localhost:4300/frameRedirect.html',
    },
    cache: {
      cacheLocation: BrowserCacheLocation.LocalStorage,
      storeAuthStateInCookie: isIE, // set to true for IE 11
    },
    system: {
      loggerOptions: {
        loggerCallback,
        logLevel: LogLevel.Info,
        piiLoggingEnabled: false
      }
    },
  })
}
export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
  const protectedResourceMap = new Map<string, Array<string>>();
  protectedResourceMap.set('https://graph.microsoft.com/v1.0/me', ['user.read']);

  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap,
  };
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return {
    interactionType: InteractionType.Redirect,
    authRequest: {
      scopes: ['user.read'],
    }
  };
}

@NgModule({
  declarations: [
    AppComponent,
    ConfigurationComponent,
    CreateComponent,
    ViewComponent,
    WorkQComponent,
    FooterComponent,
    NavigationBarComponent,
    MultiCheckFilterComponent,
    AdministrationComponent,
    AccessDeniedComponent,
    OffVpnComponent,
    SafeurlPipe,
    PopupAnchorDirective,
    OverlayComponent,
    PopupAnchorDirective,
    OverlayComponent
  ],
  imports: [
    RouterModule , NgbModule,
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    ApplicationInsightsModule.forRoot({
      // instrumentationKey: environment.appInsights.instrumentationKey // backup
      instrumentationKeySetLater: true // WH 29Jan2024 - transition to connectionString
    }),
    AppRoutingModule,
    GridModule,
    DropDownsModule,
    InputsModule,
    FormsModule,
    ReactiveFormsModule,
    LayoutModule,
    IconsModule,
    LabelModule,
    DateInputsModule,
    ExcelModule,
    BrowserModule,
    BrowserAnimationsModule,
    DialogsModule, UploadsModule,
    MsalModule, PdfViewerModule, ToolBarModule, ButtonGroupModule, SortableModule,
    ButtonsModule, BadgeModule, LoaderModule, ListBoxModule, ListViewModule, PopupModule, IndicatorsModule,
    ContextMenuModule, NotificationModule
  ],
  providers: [
   AppInsightsService,
   { provide: ErrorHandler, useClass: ErrorHandlerService },
   { provide: LoggingService, useClass: LoggingService },

    { provide: HttpErrorHandler, useClass: HttpErrorHandler },
    { provide: RM_NotificationService, useClass: RM_NotificationService },
    { provide: ErrorHandlerService, useClass: ErrorHandlerService },
    { provide: LoggingService, useClass: LoggingService },
    {
      // get tokens and add to Http req.
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true
    },
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: MSALGuardConfigFactory
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: MSALInterceptorConfigFactory
    },
    MsalService,
    MsalGuard,
    MsalBroadcastService,
    StartupService,
    {
      // initialize factory before app initialization/bootstrapping
      provide: APP_INITIALIZER,
      useFactory: startupServiceFactory,
      deps: [StartupService],
      multi: true
    },
    StatePersistingService,
    DatePipe,
    QueryApiService,
    CommentsApiService,
    RecordApiService,
    UserService,
  ],
  bootstrap: [AppComponent, MsalRedirectComponent]
})
export class AppModule {
  // WH 29Jan2024 - transition to connectionString
  constructor(private appInsightService: AppInsightsService,
              private stringParsingService: StringParsingService) {
    const key = this.stringParsingService.parseConnectionString(environment.appInsights.connectionString, 'InstrumentationKey')

    appInsightService.config = {
      instrumentationKey: key? key : environment.appInsights.instrumentationKey
    }
    appInsightService.init();
  }
}
